<template>
    <div>
        <common-header></common-header>

        <div class="subtop">
            <div class="subtopin">
                <img :src="require('@/assets/img/sub/sub_top_img.png')"/>
                <div class="subtit">RECRUIT</div>
            </div>
        </div>

        <div class="sbreadcrumb">
            <div class="bdinner">
                <span><img :src="require('@/assets/img/sub/home_icon.svg')"></span>
                <span><img :src="require('@/assets/img/sub/next_arrow.svg')"></span>
                <span class="locatit">RECRUIT</span>
            </div>

        </div>

        <div class="recruit_wrap">
            <div class="title_wrap">
                <div class="title">
                    <div>2022</div>
                    <div class="recruit_title">틴텍씨앤씨 채용 공고</div>
                </div>
                <div class="title_img_wrap">
                    <img :src="require('@/assets/img/sub/recruit_img.svg')"/>
                </div>
            </div>
            <div class="inner_text_box">
                <div class="sub_title">담당업무</div>
                <ul>
                    <li>자사 서비스 플랫폼 개발</li>
                    <li>비상주 외부 프로젝트 (자사 사무실에서 근무)</li>
                </ul>
                <div class="sub_title">채용분야</div>
                <ul>
                    <li>프론트엔드 개발자 [신입, 경력]</li>
                    <li>프론트 프레임 워크 UI 컴포넌트 개발자 [신입, 경력]</li>
                    <li>백엔드 개발자 [신입, 경력]</li>
                </ul>
                <div class="sub_title">지원 자격</div>
                <ul>
                    <li>프론트엔드 분야 필수 조건</li>
                    <ul>
                        <li>javascript ECMA2015(ES5)</li>
                        <li>vue.js</li>
                    </ul>
                </ul>
                <ul>
                    <li>백엔드 분야 필수 조건</li>
                    <ul>
                        <li>JAVA 8 이상</li>
                        <li>스프링부트</li>
                        <li>SQL</li>
                    </ul>
                </ul>
                <ul>
                    <li>우대사항</li>
                    <ul>
                        <li>해당직무 근무경험</li>
                        <li>다음 기술을 이용하여 실무를 경험하신 분
                            <ul class="preferential_wrap">
                                <li>언어
                                    <ul>
                                        <li>- javascript (ES5), Typescript, Coffeescript, Python</li>
                                        <li>- kotlin, JAVA</li>
                                        <li>- C#, C, C++</li>
                                    </ul>
                                </li>
                                <li>JPS</li>
                                <li>스타일 시트를 다루실 수 있는 분
                                    <ul>
                                        <li>- css, sass, scss</li>
                                    </ul>
                                </li>
                                <li>모바일 네이티브
                                    <ul>
                                        <li>- Android, iOS</li>
                                    </ul>
                                </li>
                                <li>프레임워크
                                    <ul>
                                        <li>- .NET, Xamarin</li>
                                        <li>- Vue.js, Nuxt.js, React, Next.js</li>
                                    </ul>
                                </li>
                                <li>Jupyter</li>
                                <li>Kubernetes</li>
                                <li>CI/CD 경험</li>
                                <li>퍼블릭 클라우드 경험</li>
                                <li>그 외 네트워크 등 인프라 지식을 갖추신 분</li>
                            </ul>
                        </li>
                    </ul>
                </ul>
                <div class="sub_title">지원 방법</div>
                <ul>
                    <li>사람인, 잡코리아, 인크루트를 통해 지원해주세요.</li>
                </ul>

            </div>

        </div>

        <common-footer></common-footer>
    </div>


</template>

<style scoped>
    .subtop {
        width: 100%;
        height: 296px;
        border-top: 1px solid #ddd;
    }

    .subtop .subtopin {
        width: 1200px;
        margin: 0 auto;
    }

    .subtop .subtopin img {
        width: 418px;
        display: inline-block;
        margin-left: 10%;
    }

    .subtop .subtopin .subtit {
        display: inline-block;
        font-size: 4.5rem;
        letter-spacing: -2.25px;
        font-weight: 900;
        color: #000000;
        vertical-align: middle;
    }

    .sbreadcrumb {
        width: 100%;
        background: #0F70B7;
        height: 62px;
    }

    .sbreadcrumb .bdinner {
        width: 1200px;
        margin: 0 auto;
        padding-left: 20px;
    }

    .sbreadcrumb .bdinner span {
        padding-right: 20px;
        vertical-align: bottom;
        display: inline-block;
        padding-top: 19px;
    }

    .sbreadcrumb .bdinner span img {
        vertical-align: sub;
    }

    .sbreadcrumb .bdinner span.locatit {
        font-size: 1.8rem;
        color: #fff;
        font-weight: 900;
        padding-top: 13px;
        display: inline-block;
    }


    /**********************************************/
    /*****************2022.01.03 pc ***************/
    /**********************************************/

    .recruit_wrap {
        width: 43%;
        padding: 24px;
        /*background: linear-gradient(90deg, rgba(67, 142, 197, 1) 0%, rgba(15, 112, 183, 1) 100%);*/
        background-color: #D2E1F1;
        margin: 34px auto;
        border-radius: 4px;
    }

    .title_wrap {
        display: flex;
    }

    .title {
        font-size: 5.5rem;
        color: #0F70B7;
        font-weight: bold;
        letter-spacing: -1px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 7%;
    }

    .title > .recruit_title {
      line-height: 5.5rem;
      padding-bottom: 1rem;
    }

    .title > div:first-child {
        margin-bottom: 33px;
        font-size: 4rem;
    }

    .title_img_wrap {
        width: 28%;
    }

    .title_img_wrap img {
        width: 100%;
    }

    .inner_text_box {
        background-color: white;
        width: 100%;
        height: auto;
        border-radius: 8px;
        padding: 23px;
    }

    .sub_title {
        color: #428DC5;
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 14px;
    }

    ul li {
        font-size: 1.4rem;
        color: #333;
        line-height: 2;
    }

    .preferential_wrap > li {
        list-style-type: circle !important;
    }

    .preferential_wrap ul {
        padding-left: 12px;
    }

    .preferential_wrap ul li {
        list-style-type: none;
    }

    /*---------------------------------------------------------------------------------------------------tablet*/
    @media screen and (max-width: 1199px) and (min-width: 750px) {

        /**********************************************/
        /*****************2022.01.03 tablet ***********/
        /**********************************************/
        .recruit_wrap {
            width: 92%;
            padding: 24px;
            /*background: linear-gradient(90deg, rgba(67, 142, 197, 1) 0%, rgba(15, 112, 183, 1) 100%);*/
            background-color: #D2E1F1;
            margin: 34px auto;
            border-radius: 4px;
        }

        .title_wrap {
            display: flex;
        }

        .title {
            font-size: 4.5rem;
            color: #0F70B7;
            font-weight: bold;
            letter-spacing: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 13%;
        }

        .title > div:first-child {
            margin-bottom: 20px;
            font-size: 3.5rem;
        }

        .title_img_wrap {
            width: 28%;
        }

        .title_img_wrap img {
            width: 100%;
        }

        .inner_text_box {
            background-color: white;
            width: 100%;
            height: auto;
            border-radius: 8px;
            padding: 23px;
        }

        .sub_title {
            color: #428DC5;
            font-size: 2rem;
            font-weight: 500;
            margin-bottom: 14px;
        }

        ul li {
            font-size: 1.4rem;
            color: #333;
            line-height: 2;
        }

        .preferential_wrap > li {
            list-style-type: circle !important;
        }

        .preferential_wrap ul {
            padding-left: 12px;
        }

        .preferential_wrap ul li {
            list-style-type: none;
        }

    }


    /*---------------------------------------------------------------------------------------------------mobile*/
    @media (max-width: 749px) {

        .subtop {
            width: 100%;
            height: 97px;
            border-bottom: 1px solid #0F70B7;
        }

        .subtop .subtopin {
            width: 100%;
            margin: 0 auto;
        }

        .subtop .subtopin img {
            width: 135px;
            display: inline-block;
            margin-left: 10%;
        }

        .subtop .subtopin .subtit {
            display: inline-block;
            font-size: 2rem;
            letter-spacing: -1px;
            font-weight: 900;
            color: #000000;
            vertical-align: middle;
        }

        .sbreadcrumb {
            display: none;
        }

        /**********************************************/
        /*****************2022.01.03 mobile ***********/
        /**********************************************/
        .recruit_wrap {
            width: 100%;
            padding: 12px;
            background-color: #D2E1F1;
            margin: 0;
            border-radius: 0px;
        }

        .title_wrap {
            display: flex;
            justify-content: space-between;
            padding-right: 8px;
        }

        .title {
            font-size: 2rem;
            color: #0F70B7;
            font-weight: bold;
            letter-spacing: -1px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .title > div:first-child {
            font-size: 2rem;
            margin-bottom: 0;
        }

        .title_img_wrap {
            width: 28%;
        }

        .title_img_wrap img {
            width: 100%;
        }

        .inner_text_box {
            background-color: white;
            width: 100%;
            height: auto;
            border-radius: 8px;
            padding: 8px;
        }

        .sub_title {
            color: #428DC5;
            font-size: 1.6rem;
            font-weight: 500;
            margin-bottom: 4px;
        }

        ul {
            padding-left: 28px;
        }

        ul li {
            font-size: 1.4rem;
            color: #333;
            line-height: 2;
        }

        .preferential_wrap > li {
            list-style-type: circle !important;
        }

        .preferential_wrap ul {
            padding-left: 8px;
        }

        .preferential_wrap ul li {
            list-style-type: none;
        }


    }


</style>


<script>
    import CommonHeader from "@/components/CommonHeader";
    import CommonFooter from "@/components/CommonFooter";

    export default {
        name: "About",
        components: {
            CommonFooter,
            CommonHeader
        },
        data() {
            return {
                imgUrl: require('@/assets/img/landing/tb_logo2.png'),
                loading: true
            }
        },
        created() {

        },
        mounted() {
            setTimeout(() => {
                this.loading = false;
            }, 2000)
        }
    }
</script>

